import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Section from "components/Section"

const Content = () => {
  const { t } = useLocale()

  const data = [
    t(
      "Możliwość rozwoju poprzez aktywne uczestnictwo w ciekawych projektach świadczonych dla międzynarodowych grup kapitałowych."
    ),
    t(
      "Współpraca z najlepszymi polskimi prawnikami, wielokrotnie wyróżnianymi w rankingach prawniczych."
    ),
    t("Pracę w młodym i dynamicznym zespole."),
    t("Stabilne zatrudnienie w Kancelarii o ugruntowanej pozycji na rynku."),
    t("Komfortowe i nowoczesne biuro w doskonałej lokalizacji."),
  ]

  return (
    <Section className="career-content" top={false}>
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <h3>
            {t(
              "Aktywnie poszukujemy talentów! Jeśli szukasz pracy z możliwością nauki i rozwoju w obszarze prawa koniecznie sprawdź kogo aktualnie poszukujemy i nie zwlekaj z aplikacją!"
            )}
          </h3>
          <img
            src={require("assets/images/career-content-image.jpg").default}
            alt=""
          />

          <div>
            <div className="row">
              <div className="col-lg-6 offset-lg-6">
                <h3>{t("Oferujemy")}:</h3>
                <ul>
                  {data.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Content
