import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Section from "components/Section"
import CareerForm from "components/CareerForm"

const Baner = () => {
  const { t } = useLocale()

  return (
    <Section className="career-baner">
      <div className="row">
        <div className="col-lg-5">
          <h2>{t("Aplikuj, kiedy chcesz")}</h2>
        </div>
        <div className="col-lg-7 col-xl-5 offset-xl-1">
          <CareerForm />
        </div>
      </div>
    </Section>
  )
}

export default Baner
